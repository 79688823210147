import React from "react";
import { Link } from "react-router-dom";
import {
  G1,
  G2,
  G3,
  G4,
  G5,
  G6,
  G7,
  G8,
  G9,
  G10,
  G11,
  G12,
  G13,
  G14,
  G15,
  G16,
  G17,
  G18,
  G19,
  G20,
  G21,
  G22,
  G23,
  G24,
  G25,
  G26,
  G27,
  G28,
  G29,
  G30,
  G31,
  G32,
  G33,
  G34,
  G35,
  G36,
  G37,
} from "../images";

const Gallery = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Gallery</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Gallery</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="gallery-bg">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G1} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G2} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G3} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G4} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G5} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G6} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G7} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G8} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G9} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G10} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G11} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G12} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G13} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G14} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G15} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G16} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G17} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G18} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G19} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G20} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G21} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G22} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G23} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G24} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G25} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G26} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G27} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G28} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G29} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G30} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G31} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G32} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G33} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G34} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G35} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G36} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={G37} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
