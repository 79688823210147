import React from "react";
import { Link } from "react-router-dom";
import {
  // AmayFaculties,
  DebashisFaculties,
  // DineshFaculties,
  KunalFaculties,
  MehebubFaculties,
  MithunImg,
  // MonjuFaculties,
  MontiFaculties,
  MousumiFaculties,
  NazmaFaculties,
  PrasannaFaculties,
  // SagarFaculties,
  SouravFaculties,
  SushamFaculties,
} from "../images";

const Faculties = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Faculties</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Faculties</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="prospectus-structure">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={DebashisFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Debashis Mondal</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Principal</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: B.TECH in ME</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 10 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Principal</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 9339955219</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: gaatpvtiti@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={MontiFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Monti Das</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Instructor</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: B.TECH in ME</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 8 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Fitter</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 9339955219</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: gaatpvtiti@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={PrasannaFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Prasanna Mondal</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Instructor</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: B.TECH in ME</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 4 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Fitter</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 9339955219</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: gaatpvtiti@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={SushamFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Susham Nath</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Instructor</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: B.TECH in ME</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 4 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Welder</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 9339955219</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: gaatpvtiti@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={MehebubFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Mehebub Alam</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Instructor</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: B.TECH in EE</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 4 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Electrician</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 9339955219</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: gaatpvtiti@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={SouravFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Sourav Shaw</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Instructor</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: B.TECH in ME</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 3 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Fitter</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 9339955219</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: gaatpvtiti@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={NazmaFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Nazma Parvin</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Instructor</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: B.TECH in EE</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 3 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Electrician</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 9339955219</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: gaatpvtiti@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={KunalFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Kunal Ghosh</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Instructor</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: B.TECH in EE</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 4 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Electrician</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 9339955219</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: gaatpvtiti@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={MousumiFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Mousumi Ghosh</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Instructor</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: MA in English, B.ED</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 4 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: English</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 9339955219</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: gaatpvtiti@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={MithunImg} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Mithun Mondal</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Workshop Instructor</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Diploma in EE</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 2 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Electrician</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 9339955219</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: gaatpvtiti@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={MonjuFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Monju Khatun</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Computer Operator</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: CITA, DITA</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 5 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Data Entry</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 9339955219</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: gaatpvtiti@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={AmayFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Amay Chakroborty</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Head Clerk</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Graduate</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 12 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Head Clerk</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 9339955219</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: gaatpvtiti@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={SagarFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Sagar Barik</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Group D</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Madhyamik</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 4 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Group D</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 9339955219</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: gaatpvtiti@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={DineshFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Dinesh Halder</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Group D</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Madhyamik</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 5 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Group D</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 9339955219</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: gaatpvtiti@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faculties;
