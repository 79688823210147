import React from "react";
import { Link } from "react-router-dom";
import { DebashisFaculties } from "../images";

const PrincipalMessage = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Principal Message</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Principal's Message</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="features-area Principal_sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12">
              <div className="features-img text-center">
                <img src={DebashisFaculties} alt="" />
                <h4>Mr. Debashis Mondal</h4>
                <p>(Principal)</p>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="features-content">
                <p>
                  I am delighted to welcome you to GAAT Pvt. ITI, a leading
                  centre for Industrial Training Institute in this part of the
                  world. We started our journey in the year of 2011 by GAAT
                  FOUNDATION TRUST in ecofriendly atmosphere to fulfill the
                  motto of quality Education. Education is must for both men and
                  women equally as both together make a healthy and educated
                  society. It is an essential tool for getting bright future as
                  well as plays a most important role in development and
                  progress of the country.
                </p>
                <p>
                  There is huge scope to develop this ITI thereby catalyzing the
                  mainstreaming of alienated rural youth by providing them
                  access to productive & decent work through skill development
                  route. I strongly believe that through SKILL TRAINING we can
                  convert this ITI into a skill hub of this Murshidabad District
                  by providing de- novo skilling to fresher, up-skilling to ITI
                  graduates, re-skilling to existing workers with special
                  emphasis to women and disadvantaged section of the society.{" "}
                </p>
                <p>
                  As a new joiner, I am highly obliged to serve in Fitter,
                  Electrician, Welder of this esteemed institution. Discipline
                  is one of the main motto of our organization. ITI Trainees can
                  work in various specialized area like railway, automotive
                  system, various industry sectors, design engineering, dynamic
                  system and control, power plant engineering, etc. and most
                  importantly in software field also.
                </p>
                <p>
                  The institute has well qualified and experienced faculty
                  members and they are committed to excellence in teaching.
                  Interactive relationship is maintained between students and
                  the staff which enables the students to develop sound
                  foundation. The faculty aims at putting extra ordinary effort
                  towards motivating the students in solving actual industrial
                  problems by undertaking various projects, industrial visits
                  and in-plant training. The Institution is actively associated
                  in grooming the trainees in ITI by nurturing them with inputs
                  from various basic and specialized subjects.
                </p>
                <p>
                  I am personally satisfied to serve this department along with
                  cooperative faculty members and help students to be well
                  skilled trainees in recent FUTURE.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrincipalMessage;
