import React from "react";
import { Link } from "react-router-dom";

const AdmissionCriteria = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Admission Criteria</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Admission Criteria</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="courses-offered">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Name of Trade under (NCVT)</th>
                      <th scope="col">Duration of Training</th>
                      <th scope="col">Eligibility Qualification</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>FITTER</td>
                      <td>Two years </td>
                      <td>10th Standard</td>
                    </tr>
                    <tr>
                      <td>ELECTRICIAN</td>
                      <td>Two years</td>
                      <td>10th Standard</td>
                    </tr>
                    <tr>
                      <td>WELDER</td>
                      <td>One year</td>
                      <td>8th Standard</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdmissionCriteria;
