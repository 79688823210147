import "./App.css";
import { Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Faculties from "./pages/Faculties";
import ChairmanMessage from "./pages/ChairmanMessage";
import PrincipalMessage from "./pages/PrincipalMessage";
import AboutCollege from "./pages/AboutCollege";
import PlacementCell from "./pages/PlacementCell";
import AntiRaggingCell from "./pages/AntiRaggingCell";
import GrievanceCell from "./pages/GrievanceCell";
import Contact from "./pages/Contact";
import RecognitionAffiliation from "./pages/RecognitionAffiliation";
import Gallery from "./pages/Gallery";
import CourseDetails from "./pages/CourseDetails";
import AdmissionCriteria from "./pages/AdmissionCriteria";
import AdmissionProcess from "./pages/AdmissionProcess";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="about-college" element={<AboutCollege />} />
          <Route path="faculties" element={<Faculties />} />
          <Route path="chairman-message" element={<ChairmanMessage />} />
          <Route path="principal-message" element={<PrincipalMessage />} />
          <Route path="placement-cell" element={<PlacementCell />} />
          <Route path="anti-ragging-cell" element={<AntiRaggingCell />} />
          <Route path="grievance-cell" element={<GrievanceCell />} />
          <Route
            path="recognition-affiliation"
            element={<RecognitionAffiliation />}
          />
          <Route path="gallery" element={<Gallery />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="course-details" element={<CourseDetails />} />
          <Route path="admission-criteria" element={<AdmissionCriteria />} />
          <Route path="admission-process" element={<AdmissionProcess />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
