import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

export default function PdfLoader({ pdfUrl }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <div>
      <Viewer
        fileUrl={pdfUrl}
        plugins={[defaultLayoutPluginInstance]}
        style={{
          border: "1px solid rgba(0, 0, 0, 0.3)",
        }}
      />
    </div>
  );
}
