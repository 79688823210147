import React from "react";
import { Link } from "react-router-dom";
import { CourseImg, ElectricianImg, WelderImg } from "../images";

const CourseDetails = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Course Details</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Course Details</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="infrastructure-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="text-center mb-5">COURSES WE OFFER</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <h5>FITTER</h5>
              <p>
                <b>COURSE DURATION: </b>2 YEARS
              </p>
              <p>
                <b>Entry Qualification: </b>Passed 10th Class or its equivalent.
              </p>
              <p>
                <b>Job Role/Opportunities: </b>On successful completion of this
                course, the candidates shall be gain fully employed in the
                following industries:
              </p>
              <p>
                <b>1.</b>Production & Manufacturing industries. <br />
                <b>2.</b>Structural Fabrication like bridges, roof structures,
                etc. <br />
                <b>3.</b>Automobile and allied industries <br />
                <b>4.</b>Service industries like road transportation and
                railways
              </p>
            </div>
            <div className="col-lg-4 align-self-center">
              <img src={CourseImg} alt="" />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-8 align-self-center">
              <h5>ELECTRICIAN</h5>
              <p>
                <b>COURSE DURATION: </b>2 YEARS
              </p>
              <p>
                <b>Entry Qualification: </b>Passed 10th Class or its equivalent.
              </p>
              <p>
                <b>Job Role/Opportunities: </b>On successful completion of this
                course, the candidates shall be gain fully employed in the
                following industries:
              </p>
              <p>
                <b>1.</b>All state Electricity Boards and departments, public
                sectors, MNC, <br />
                <b>2.</b>Private and Govt. Industries, License Certificate for
                self-employment. <br />
                <b>3.</b>Wiring Contractors, Huge job opportunities in power
                generation, transmission, distribution station, huge abroad job
                opportunities. <br />
              </p>
            </div>
            <div className="col-lg-4 align-self-center">
              <img src={ElectricianImg} alt="" />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-8 align-self-center">
              <h5>WELDER</h5>
              <p>
                <b>COURSE DURATION: </b>1 YEAR
              </p>
              <p>
                <b>Entry Qualification: </b>Passed 8th Class
              </p>
              <p>
                <b>Job Role/Opportunities: </b>On successful completion of this
                course, the candidates shall be gain fully employed in the
                following industries:
              </p>
              <p>
                <b>1.</b>Automobile and Allied Industries <br />
                <b>2.</b>Manufacturing Industries and Railways. <br />
              </p>
            </div>
            <div className="col-lg-4 align-self-center">
              <img src={WelderImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetails;
