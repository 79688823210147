import React, { useEffect, useState } from "react";

import Slider from "../components/Slider/Slider";
import { Link } from "react-router-dom";
import { FaGraduationCap } from "react-icons/fa";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { toast } from "react-toastify";
import { makeAnContact, newNotice } from "../Service/Home/index";
import { EmailValidate, VisibleError } from "../components/Helper/VisibleError";
import {
  AboutOne,
  // AboutThree,
  AboutTwo,
  ChairmanImages,
  G1,
  G10,
  G11,
  G12,
  G13,
  G14,
  G15,
  G16,
  G17,
  G18,
  G19,
  G2,
  G20,
  G21,
  G22,
  G23,
  G24,
  G25,
  G26,
  G27,
  G28,
  G29,
  G3,
  G30,
  G31,
  G32,
  G33,
  G34,
  G35,
  G36,
  G37,
  G4,
  G5,
  G6,
  G7,
  G8,
  G9,
  Partner_1,
  Partner_10,
  Partner_11,
  Partner_2,
  Partner_3,
  Partner_4,
  Partner_5,
  Partner_6,
  Partner_7,
  Partner_8,
  Partner_9,
  affaliationOne,
  affaliationThree,
  affaliationTwo,
} from "../images";
import { BsArrowRight } from "react-icons/bs";
const Home = () => {
  const [allNoticeStock, setAllNoticeStock] = useState([]);
  const [handelContactError, setHandelContactError] = useState({
    name: false,
    email: false,
    mobileNo: false,
    massage: false,
    emailValidate: false,
  });

  const [handelContact, setHandelContact] = useState({
    name: "",
    email: "",
    mobileNo: "",
    massage: "",
  });

  const handleChange = (e, max = 10) => {
    let { name, value } = e.target;
    if (name === "mobileNo") {
      if (value > max) {
        value = value?.slice(0, max);
      }
    }

    setHandelContact((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handelSubmit = async () => {
    let checkError = false;
    if (handelContact.name === "") {
      setHandelContactError((item) => {
        return {
          ...item,
          name: true,
        };
      });
      checkError = true;
    }
    if (handelContact.email === "") {
      setHandelContactError((item) => {
        return {
          ...item,
          email: true,
        };
      });
      checkError = true;
    } else if (!EmailValidate(handelContact.email)) {
      setHandelContactError((item) => {
        return {
          ...item,
          emailValidate: true,
        };
      });
      checkError = true;
    }
    if (handelContact.mobileNo === "") {
      setHandelContactError((item) => {
        return {
          ...item,
          mobileNo: true,
        };
      });
      checkError = true;
    }
    if (handelContact.massage === "") {
      setHandelContactError((item) => {
        return {
          ...item,
          massage: true,
        };
      });
      checkError = true;
    }
    if (checkError) {
      return;
    }
    try {
      const payload = {
        data: {
          name: handelContact.name,
          email: handelContact.email,
          phone: handelContact.mobileNo,
          description: handelContact.massage,
        },
      };
      const res = await makeAnContact(payload);
      if (res?.status === 200) {
        toast.success("Send Message Successfully");
        setHandelContact({
          name: "",
          email: "",
          mobileNo: "",
          massage: "",
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getNotice = async () => {
    try {
      const res = await newNotice();
      console.log(res.data);
      setAllNoticeStock(res.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getNotice();
  }, []);

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    smartSpeed: 1000,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };
  const partners = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    autoplay: true,
    smartSpeed: 1000,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
      1200: {
        items: 6,
      },
    },
  };
  return (
    <>
      <Slider />
      {/* services area */}
      <div className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              {/* <Link to={IsoCertificate} target="_blank"> */}
              <div className="services-box">
                <div className="services-inner">
                  <div className="icon">
                    <img src={affaliationOne} alt="Udyam logo" />
                  </div>
                  <div className="text">
                    <h5>Directorate General of Training</h5>
                    {/* <p>
                      Seamlessly visualize quality ellectual capital without
                      superior collaboration and idea sharing listically
                    </p>
                    <Link to="/">
                      Read More <BsArrowRight />
                    </Link> */}
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              {/* <Link to={UdyamCertificate} target="_blank"> */}
              <div className="services-box active">
                <div className="services-inner">
                  <div className="icon">
                    <img src={affaliationTwo} alt="PCI logo" />
                  </div>
                  <div className="text">
                    <h5>Skill India</h5>
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              {/* <Link to={Wbsct} target="_blank"> */}
              <div className="services-box">
                <div className="services-inner">
                  <div className="icon">
                    <img src={affaliationThree} alt="WBSCT logo" />
                  </div>
                  <div className="text">
                    <h5>ITI</h5>
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="mandatory">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-md-3 col-lg-2">
              <p>Latest News:</p>
            </div>
            <div className="col-sm-9 col-md-9 col-lg-10">
              <marquee width="100%" direction="left">
                {allNoticeStock && (
                  <p className="mb-0">
                    {
                      allNoticeStock[allNoticeStock.length - 1]?.attributes
                        ?.description
                    }
                  </p>
                )}
              </marquee>
            </div>
          </div>
        </div>
      </div>
      {/* about area */}
      <div className="about-area">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <img src={AboutOne} className="about_first" alt="" />
                </div>
                <div className="col-sm-6">
                  <img src={AboutTwo} className="about_second mb-3" alt="" />
                  <img src={G10} className="about_second" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about-title">
                <h5>
                  <FaGraduationCap /> About Our College
                </h5>
                <h2>A Few Words About the College</h2>
                <p className="txt-clr">
                  Being established in the year 2011, GAAT Foundation Trust with
                  a view to extend educational facility among all backward
                  classes, downtrodden, minority community and SC/ST people
                  switch over the task from educational training to technical
                  educational training which has ever increasing demand in the
                  modern society and so finally decided to set up an Industrial
                  Training Institute as their primary task. On this challenging
                  path, the trust purchased a total area of 30.83 dec. land with
                  an old heritage building at Jemo ,Ruppur under Kandi
                  Municipality of Murshidabad District in West Bengal. The Trust
                  with its noble wish beautified and thereby renovated the
                  building to a unique shape in which the main building is
                  surrounded with four workshops, two in each row just like a
                  guard of honor and it is extended with classrooms, conference
                  room corporate like office rooms, library room, entertainment
                  room, store room, separate toilet and bathrooms for ladies and
                  gents and also is adhered with a marvelous guest room. At a
                  glance, the Institute looks like a heritage building,
                  retaining its antique value which surely attracts the
                  passerby.
                </p>
                <Link
                  className="btn mt-2"
                  to="/about-college"
                  style={{ marginRight: "7px" }}
                >
                  Read More
                  <BsArrowRight />
                </Link>
              </div>
              {/* <div className="about-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="about-content">
                      <ul className="mt-4">
                        <li>
                          <div className="content">
                            <div className="text">
                              <h3>Our Vision</h3>
                              <p>
                                To create the pharmacy institution as “centre of
                                excellence” in learning, knowledge, creativity,
                                positivity and discipline and to prepare the
                                students to participate in pharmacy education
                                and research as well as in social activities.
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="content">
                            <div className="text">
                              <h3>Our Mission</h3>
                              <p>
                                To impart leadership attitude, entrepreneurship,
                                co-operative spirit, cultured outlook, ethical
                                values, social responsibilities and healthy
                                habits among the students to contribute to the
                                technological, economic and social development
                                in the region.
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="partner-area">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="about-title text-center">
                <h2 className="mb-0 pb-2">Our Hiring Partners</h2>
                <p>
                  We Want You to Make the Most of Our Efforts in Placing You
                  with Our Clientele
                </p>
              </div>
            </div>
            <div className="col-sm-12 pt-4">
              <OwlCarousel className="slider-items owl-carousel" {...partners}>
                <div className="item">
                  <div className="partner_img">
                    <img src={Partner_1} alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="partner_img">
                    <img src={Partner_2} alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="partner_img">
                    <img src={Partner_3} alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="partner_img">
                    <img src={Partner_4} alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="partner_img">
                    <img src={Partner_5} alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="partner_img">
                    <img src={Partner_6} alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="partner_img">
                    <img src={Partner_7} alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="partner_img">
                    <img src={Partner_8} alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="partner_img">
                    <img src={Partner_9} alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="partner_img">
                    <img src={Partner_10} alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="partner_img">
                    <img src={Partner_11} alt="" />
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      {/* features area */}
      <div className="features-area">
        <div className="animations-10">
          <img
            src="https://wpdemo.zcubethemes.com/qeducato/wp-content/uploads/2023/03/an-img-10.png"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row align-items-center message-reverse">
            <div className="col-lg-7 col-md-12">
              <div className="features-content">
                <h2>Chairman's Message</h2>
                <p>
                  Dear students, We welcome you as a member of our family. Our
                  educational college is meant for the complete physical,
                  intellectual, emotional, social, aesthetic, moral and
                  spiritual development of pupil and teachers. This college has
                  made long strides in every sphere and has carved a special
                  ornament for itself in the field of education. Being
                  associated with this institution, we are committed to provide
                  a forum to the students and faculty, not only to express their
                  experiences and thoughts but also unfold their hidden talents.
                  The sole mission of this institute is to enable the students
                  not only to make themselves successful in their professional
                  field. I am confident that all the staff members, who are
                  back-bone of educational institute will work as one team, as
                  they have been working in past, to build a bright future. We
                  are here with all our facilities to support you in building
                  your career and personality. In technical education not only
                  knowledge but skill and extra co-curricular activities add
                  extra vital role in framing all around development of budding
                  technical fresher and here we support all those activities.
                </p>
                {/* <ul>
                  <li>
                    <div className="step-box">
                      <div className="icon-box">
                        <img
                          src="https://wpdemo.zcubethemes.com/qeducato/wp-content/uploads/2023/03/fea-icon01.png"
                          alt=""
                        />
                      </div>
                      <div className="text">
                        <h3>Skilled Teachers</h3>
                        <p>
                          Special wedding garments are often worn, and the
                          ceremony is sttimes followed by a wedding reception.
                          Music, poetry, prayers, or readings from.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="step-box">
                      <div className="icon-box">
                        <img
                          src="https://wpdemo.zcubethemes.com/qeducato/wp-content/uploads/2023/03/fea-icon01.png"
                          alt=""
                        />
                      </div>
                      <div className="text">
                        <h3>Skilled Teachers</h3>
                        <p>
                          Special wedding garments are often worn, and the
                          ceremony is sttimes followed by a wedding reception.
                          Music, poetry, prayers, or readings from.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="step-box">
                      <div className="icon-box">
                        <img
                          src="https://wpdemo.zcubethemes.com/qeducato/wp-content/uploads/2023/03/fea-icon01.png"
                          alt=""
                        />
                      </div>
                      <div className="text">
                        <h3>Skilled Teachers</h3>
                        <p>
                          Special wedding garments are often worn, and the
                          ceremony is sttimes followed by a wedding reception.
                          Music, poetry, prayers, or readings from.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="features-img director-img">
                <img src={ChairmanImages} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="features-gallery">
        <div className="container">
          <div className="row">
            <OwlCarousel className="slider-items owl-carousel" {...options}>
              <div className="item">
                <div className="gallery-main">
                  <img src={G1} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G2} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G3} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G4} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G5} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G6} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G7} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G8} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G9} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G10} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G11} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G12} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G13} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G14} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G15} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G16} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G17} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G18} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G19} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G20} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G21} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G22} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G23} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G24} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G25} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G26} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G27} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G28} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G29} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G30} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G31} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G32} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G33} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G34} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G35} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G36} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="gallery-main">
                  <img src={G37} alt="" />
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
      {/* contact area */}
      <div className="contact-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="notice">
                <div className="notice_title">
                  <h3>News And Notice</h3>
                </div>
                <div className="notice_list">
                  <div
                  // className={`marquee ${isRunning ? "running" : ""}`}
                  // onMouseOver={handleMouseOver}
                  // onMouseOut={handleMouseOut}
                  >
                    <ul>
                      {allNoticeStock &&
                        allNoticeStock?.map((item, i) => (
                          <li key={i}>
                            {item.attributes.files.data?.map((ele, j) => (
                              <Link
                                key={j}
                                to={ele.attributes.url}
                                target="_blank"
                              >
                                {item?.attributes?.description}
                              </Link>
                            ))}
                            {item.attributes.isNew === true && (
                              <img
                                src="https://www.eiti.org.in/assets/froantend/images/new.gif"
                                alt=""
                              />
                            )}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="contact-bg">
                <h2 className="mb-4"> Make An Contact</h2>
                <div className="contact-form">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-2">
                        <input
                          value={handelContact.name}
                          onChange={(e) => {
                            handleChange(e);
                            setHandelContactError((item) => {
                              return {
                                ...item,
                                name: false,
                              };
                            });
                          }}
                          type="text"
                          name="name"
                          placeholder="Name"
                          className="form-control"
                        />
                        <VisibleError
                          show={handelContactError.name}
                          name="Name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-2">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="form-control"
                          value={handelContact.email}
                          onChange={(e) => {
                            handleChange(e);
                            setHandelContactError((item) => {
                              return {
                                ...item,
                                email: false,
                                emailValidate: false,
                              };
                            });
                          }}
                        />
                        <VisibleError
                          show={handelContactError.email}
                          name="Email"
                        />

                        {handelContactError.emailValidate ? (
                          <span
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingLeft: "10px",
                            }}
                          >
                            Please Enter a Valid Email!
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-2">
                        <input
                          type="tel"
                          name="mobileNo"
                          placeholder="Number"
                          className="form-control"
                          value={handelContact.mobileNo}
                          onChange={(e) => {
                            handleChange(e, 10);
                            setHandelContactError((item) => {
                              return {
                                ...item,
                                mobileNo: false,
                              };
                            });
                          }}
                        />
                        <VisibleError
                          show={handelContactError.mobileNo}
                          name="Number"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-2">
                        <textarea
                          type="text"
                          name="massage"
                          rows={5}
                          placeholder="Write your massage"
                          className="form-control"
                          value={handelContact.massage}
                          onChange={(e) => {
                            handleChange(e);
                            setHandelContactError((item) => {
                              return {
                                ...item,
                                massage: false,
                              };
                            });
                          }}
                        ></textarea>
                        <VisibleError
                          show={handelContactError.massage}
                          name="Write your massage"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      {/* <Link to="/">Submit Now</Link> */}
                      <button className="btn" onClick={handelSubmit}>
                        Submit Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
