import React from "react";
import { Link } from "react-router-dom";
import PdfLoader from "../components/Pdf/PdfLoader";
import { Grievance } from "../document";

const GrievanceCell = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Grievance Cell</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Grievance Cell</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="placement-cell pt-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h3 className="pt-4">GRIEVANCE REDRESSAL CELL :</h3>
              <p>
                <b>GAAT Pvt. ITI</b> is having its own Grievance Redressal
                mechanism in an informal manner i.e. through direct supervision
                of the Principal. But in formal sense along with the provision
                of Online contact form through our website as well as suggestion
                Box.
              </p>
              <p>
                <b>Purpose :</b> <br />
                The Grievance Redressal cell of the institute functions with the
                following purposes :-
              </p>
              <p>
                <b>•</b> To ensure a democratic environment in the campus,{" "}
                <br />
                <b>•</b> To acquaint all the faculty , Student about their
                rights and duties,
                <br />
                <b>•</b> To solve the various personal and educational related
                grievances of the teacher Student. <br />
                <b>•</b> To make the institution student friendly, and to ensure
                the qualitative as well as quantitative development of the
                institution.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="prospectus-structure">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-10">
              <PdfLoader pdfUrl={Grievance} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GrievanceCell;
