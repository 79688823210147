import React from "react";
import PdfLoader from "../components/Pdf/PdfLoader";
import { Link } from "react-router-dom";
import { Placement } from "../document";

const PlacementCell = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Placement Cell</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Placement Cell</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="placement-cell pt-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h3 className="pt-4">PLACEMENT CELL :</h3>
              <p>
                Training and Placement of the students admitted to GAAT Pvt. ITI
                have been considered with great importance and a separate cell
                with a Training and placement Officer-In-charge has been active
                mainly in the following fields.
              </p>
              <p>
                <b>Training-</b> Training is teaching, or developing in one self
                or others, any skills and knowledge that relate to specific
                useful competencies. Training has specific goals of improving
                one’s capability ,capacity , productivity and performance.
                People within many professions and occupations may refer to this
                sort of training as professional development.{" "}
              </p>
              <p>
                <b>Placement-</b> At GAAT Pvt. ITI, we offer job placement
                counseling wherein out experts will sit with a student in person
                before and after he/she finishes off his program. The objective
                is to discuss his/her career aspirations before he joins our
                courses. Even after the completion of the course, we will
                analyze and discuss the career options based on a student’s
                realizations and achievements. The placement guidance cell will
                give a proper guidance to them based on their skill levels and
                career goals. Besides guiding the students on off-campus
                interviews, we would even make every attempt to line up
                candidates for internship programs offered by companies that are
                in our client network. A plethora of internship programs are
                there to explore if you are fresh out if college; it will help
                in determining the right career path. Besides giving you a deep
                insight on your chosen field, it will even help you explore new
                opportunities and gain valuable practical experience.
              </p>
              <p>
                Our Placement Guidance Cell is focused on simplifying the entire
                process of identifying the appropriate internship programs for
                our candidates. You don’t have to run through the internship
                websites as it consumes much time and often ends up misleading
                you.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="prospectus-structure">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-10">
              <PdfLoader pdfUrl={Placement} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlacementCell;
