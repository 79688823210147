import React from "react";
import { BsFillEnvelopeCheckFill } from "react-icons/bs";
import {
  FaFacebookF,
  FaLocationArrow,
  FaPhoneVolume,
  FaWhatsapp,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <footer className="main-footer">
        <div className="side-gap">
          <div className="container">
            <div className="row">
              <div className="column col-lg-4 col-md-6">
                <div className="widget contact-widget">
                  <h3 className="widget-title">About Us</h3>
                  <div className="about-widget">
                    <p className="text">
                      Being established in the year 2011, GAAT Foundation Trust
                      with a view to extend educational facility among all
                      backward classes, downtrodden, minority community and
                      SC/ST people switch over the task from educational
                      training to technical educational training
                    </p>
                    <ul className="social-links">
                      <li>
                        <Link
                          to="https://www.facebook.com/gaatpvtitiofficial/"
                          target="_blank"
                        >
                          <FaFacebookF />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://web.whatsapp.com/send/?phone=9339955219&text&type=phone_number&app_absent=0"
                          target="_blank"
                        >
                          <FaWhatsapp />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="column col-lg-4 col-md-6">
                <div className="widget links-widget">
                  <h3 className="widget-title">Information</h3>
                  <div className="widget-content footer-links">
                    <ul>
                      <li>
                        <Link to="/about-college">About College</Link>
                      </li>
                      <li>
                        <Link to="/gallery">Gallery</Link>
                      </li>
                      <li>
                        <Link to="/faculties">Faculties</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">Contact Us</Link>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <Link to="/course-details">Course Details</Link>
                      </li>
                      <li>
                        <Link to="/admission-criteria">Admission Criteria</Link>
                      </li>
                      <li>
                        <Link to="/admission-process">Admission Process</Link>
                      </li>
                      <li>
                        <Link to="/recognition-affiliation">
                          Recognition & Affiliation
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="column col-lg-4 col-md-6">
                <div className="widget contact-widget">
                  <h3 className="widget-title">Contact Us</h3>
                  <div className="widget-content">
                    <ul className="contact-info">
                      <li>
                        <div className="icon">
                          <FaPhoneVolume />
                        </div>
                        <div className="text">
                          Helpline 24/7 <br />
                          <Link to="tel:+91 9339955219">+91 9339955219</Link>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <BsFillEnvelopeCheckFill />
                        </div>
                        <div className="text">
                          <Link to="/">Email Us</Link>
                          <br />
                          <Link to="mailto:gaatpvtiti@gmail.com">
                            gaatpvtiti@gmail.com
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <FaLocationArrow />
                        </div>
                        <div className="text">
                          GAAT PRIVATE INDUSTRIAL TRAINING INSTITUTE WHITE
                          HOUSE, JEMO BAZAR-RUPPUR, P.O.-JEMO RAJBATI,
                          P.S.-KANDI, DIST.-MURSHIDABAD, WEST BENGAL, PIN-742140
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="widget about-widget mb-0">
                  <ul className="social-links">
                    <li>
                      <Link to="/">
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <BsTwitter />
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="wrapper-box">
              <div className="text">
                &copy; 2024 Gaat Pvt. ITI, All rights reserved.
              </div>
              <div className="text">
                Design and Developed by
                <Link to="https://www.yeasitech.com/" target="_blank">
                  &nbsp;YEASITECH
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div id="fixed-social">
        <div>
          <Link
            to="https://www.facebook.com/gaatpvtitiofficial/"
            className="fixed-facebook"
            target="_blank"
          >
            <i className="fa fa-facebook"></i> <span>Facebook</span>
          </Link>
        </div>
        <div>
          <Link
            to="https://web.whatsapp.com/send/?phone=9339955219&text&type=phone_number&app_absent=0"
            className="fixed-wp"
            target="_blank"
          >
            <i className="fa-brands fa-whatsapp"></i> <span>WhatsApp</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Footer;
