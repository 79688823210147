import React from "react";
import { Link } from "react-router-dom";
import { ChairmanImages } from "../images";

const ChairmanMessage = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Chairman's Message</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Chairman's Message</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="features-area Principal_sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12">
              <div className="features-img text-center">
                <img src={ChairmanImages} alt="" />
                <h4>Mr. Guruprosad Mukherjee</h4>
                <p>(Chairman)</p>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="features-content">
                <p>
                  Dear students, We welcome you as a member of our family. Our
                  educational college is meant for the complete physical,
                  intellectual, emotional, social, aesthetic, moral and
                  spiritual development of pupil and teachers. This college has
                  made long strides in every sphere and has carved a special
                  ornament for itself in the field of education. Being
                  associated with this institution, we are committed to provide
                  a forum to the students and faculty, not only to express their
                  experiences and thoughts but also unfold their hidden talents.
                  The sole mission of this institute is to enable the students
                  not only to make themselves successful in their professional
                  field. I am confident that all the staff members, who are
                  back-bone of educational institute will work as one team, as
                  they have been working in past, to build a bright future. We
                  are here with all our facilities to support you in building
                  your career and personality. In technical education not only
                  knowledge but skill and extra co-curricular activities add
                  extra vital role in framing all around development of budding
                  technical fresher and here we support all those activities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChairmanMessage;
