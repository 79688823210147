import React from "react";
import { FaGraduationCap } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ImageOne, ImageTwo } from "../images";

const AboutCollege = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>About College</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">About College</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area bg-white">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="about-title">
                <h5>
                  <FaGraduationCap /> About Our College
                </h5>
                <h2>A Few Words About the College</h2>
                <p className="txt-clr">
                  Being established in the year 2011, GAAT Foundation Trust with
                  a view to extend educational facility among all backward
                  classes, downtrodden, minority community and SC/ST people
                  switch over the task from educational training to technical
                  educational training which has ever increasing demand in the
                  modern society and so finally decided to set up an Industrial
                  Training Institute as their primary task.
                </p>
                <p className="txt-clr">
                  On this challenging path, the trust purchased a total area of
                  30.83 dec. land with an old heritage building at Jemo ,Ruppur
                  under Kandi Municipality of Murshidabad District in West
                  Bengal. The Trust with its noble wish beautified and thereby
                  renovated the building to a unique shape in which the main
                  building is surrounded with four workshops, two in each row
                  just like a guard of honor and it is extended with classrooms,
                  conference room corporate like office rooms, library room,
                  entertainment room, store room, separate toilet and bathrooms
                  for ladies and gents and also is adhered with a marvelous
                  guest room. At a glance, the Institute looks like a heritage
                  building, retaining its antique value which surely attracts
                  the passerby.
                </p>
                <p className="txt-clr">
                  With having proposal from the trust and after with
                  satisfactory Inspection, NCVT affiliated this Institute on
                  27th July, 2012 on Three trades i.e. Electrician, Fitter &
                  Welder and every trade will run in two shifts. Students
                  motivated to have Industrial Training preferred this Institute
                  and finally got their classes from 23rd September, 2012, the
                  inaugural date of the Institution. Experienced faculty members
                  are imparting their best effort in practical as well as theory
                  classes. In practical section, newly purchased tools and
                  equipments are also the attractive subject of the Institution
                  for its systematic arrangement. The Governing Body members of
                  the Institute with their proficiency and panoramic observation
                  in the upcoming development in educational sector recently
                  included 15 Modular Employable skill courses in addition to
                  the I.T.I. courses under due affiliation of NCVT and also
                  decided to develop a language Lab inside the Institute
                  premises. With a aim to uphold the task compatible with the
                  modern necessity, the masterminded members of the Governing
                  Body are regularly reviewing and analyzing the situation so
                  that the students are well placed in Employment Sector.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mission-vision-area pt-4">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="content">
                  <div className="text">
                    <h3>Our Mission & Vision</h3>
                    <ul>
                      <li className="txt-clr">
                        To educate local trainees and build their skills.
                      </li>
                      <li className="txt-clr">
                        To provide short-term free of cost industrial training
                        to local trainees{" "}
                      </li>
                      <li className="txt-clr">
                        To improve the human resource quality and availability
                      </li>
                      <li className="txt-clr">
                        To provide opportunities to the trainees
                      </li>
                      <li className="txt-clr">
                        To enable each trainee to master basic trade skills
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="image_1">
                      <img src={ImageOne} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="image_2">
                      <img src={ImageTwo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCollege;
