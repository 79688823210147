import React from "react";
import { Link } from "react-router-dom";
import PdfLoader from "../components/Pdf/PdfLoader";
import { RaggingCell } from "../document";

const AntiRaggingCell = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Anti Ragging Cell</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Anti Ragging Cell</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="placement-cell pt-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h3 className="pt-4">ANTI-RAGGING COMMITTEE :</h3>
              <p>
                “RAGGING IS PUNISHABLE OFFENCE AS PER LAW - RESULTING IN
                IMPRISONMENT AND / OR HEAVY PENALTY”
              </p>
              <p>
                Ref: F.No.37-3/Legal/AICTE/2009 - In exercise of the powers
                conferred under Section 23 read with Section 10 (b), (g), (p)
                and (q) of AICTE Act, 1987, the All India Council for Technical
                Education, hereby makes the following Regulations
              </p>
              <p>
                <b>1.</b>Ragging means causing physical and / or mental trauma
                to a person as a result of physical abuse, manhandling , using
                abusive language or gestures or forcing to perform acts that may
                cause physical/mental damage. <br />
                <b>2.</b>Ragging is a social, cultural and psychological menace.{" "}
                <br />
                <b>3.</b>Students are urged not to indulge in any activity
                within or outside the campus that may be construed as or amounts
                to ragging. <br />
                <b>4.</b>Any student, if found involved in any such activity
                directly or indirectly shall not only be expelled from the
                Institution, but the matter will be reported to Police / Legal
                authorities, for further necessary action.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="prospectus-structure">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-10">
              <PdfLoader pdfUrl={RaggingCell} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AntiRaggingCell;
