import React, { useState } from "react";
import {
  BsFillEnvelopeCheckFill,
  BsFillTelephoneInboundFill,
} from "react-icons/bs";
import { FaLocationArrow, FaPhoneVolume } from "react-icons/fa";
import { Link } from "react-router-dom";
import { EmailValidate, VisibleError } from "../components/Helper/VisibleError";
import { makeAnContact } from "../Service/Home";
import { toast } from "react-toastify";

const Contact = () => {
  const [handelContactError, setHandelContactError] = useState({
    name: false,
    email: false,
    mobileNo: false,
    massage: false,
    emailValidate: false,
  });

  const [handelContact, setHandelContact] = useState({
    name: "",
    email: "",
    mobileNo: "",
    massage: "",
  });

  const handleChange = (e, max = 10) => {
    let { name, value } = e.target;
    if (name === "mobileNo") {
      if (value > max) {
        value = value?.slice(0, max);
      }
    }
    setHandelContact((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handelSubmit = async () => {
    let checkError = false;
    if (handelContact.name === "") {
      setHandelContactError((item) => {
        return {
          ...item,
          name: true,
        };
      });
      checkError = true;
    }
    if (handelContact.email === "") {
      setHandelContactError((item) => {
        return {
          ...item,
          email: true,
        };
      });
      checkError = true;
    } else if (!EmailValidate(handelContact.email)) {
      setHandelContactError((item) => {
        return {
          ...item,
          emailValidate: true,
        };
      });
      checkError = true;
    }
    if (handelContact.mobileNo === "") {
      setHandelContactError((item) => {
        return {
          ...item,
          mobileNo: true,
        };
      });
      checkError = true;
    }
    if (handelContact.massage === "") {
      setHandelContactError((item) => {
        return {
          ...item,
          massage: true,
        };
      });
      checkError = true;
    }
    if (checkError) {
      return;
    }
    try {
      const payload = {
        data: {
          name: handelContact.name,
          email: handelContact.email,
          phone: handelContact.mobileNo,
          description: handelContact.massage,
        },
      };

      const res = await makeAnContact(payload);
      console.log(res);

      if (res?.status === 200) {
        toast.success("Send Message Successfully");
        setHandelContact({
          name: "",
          email: "",
          mobileNo: "",
          massage: "",
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Contact Us</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Contact Us</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-page-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="section-title t-left">
                <div className="section-main-title">
                  <h2>If you have any queries, fill free to contact us</h2>
                </div>
                <div className="section-description">
                  <p>
                    Our team is dedicated to providing prompt and helpful
                    responses to ensure you have a seamless experience.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-4 pt-4">
                <div className="contact-icon">
                  <FaLocationArrow />
                </div>
                <div className="contact-dec">
                  <h3>Address</h3>
                  <p className="mb-0">
                    GAAT PRIVATE INDUSTRIAL TRAINING INSTITUTE <br /> WHITE
                    HOUSE, JEMO BAZAR-RUPPUR <br /> P.O.-JEMO RAJBATI <br />{" "}
                    P.S.-KANDI <br /> DIST.-MURSHIDABAD <br />
                    WEST BENGAL <br /> PIN-742140
                  </p>
                </div>
              </div>
              <div className="d-flex mb-4">
                <div className="contact-icon">
                  <FaPhoneVolume />
                </div>
                <div className="contact-dec">
                  <h3>Contact Number</h3>
                  <p className="mb-0">+91 9339955219 </p>
                </div>
              </div>
              <div className="d-flex mb-4">
                <div className="contact-icon">
                  <BsFillTelephoneInboundFill />
                </div>
                <div className="contact-dec">
                  <h3>Landline Number</h3>
                  <p className="mb-0">03484-257730</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="contact-icon">
                  <BsFillEnvelopeCheckFill />
                </div>
                <div className="contact-dec">
                  <h3>Email Address</h3>
                  <p className="mb-0">gaatpvtiti@gmail.com </p>
                </div>
              </div>
              {/* <div className="section-title t-left mt-4">
                <div className="section-main-title">
                  <h2 className="mb-3">Principal Contact Number</h2>
                </div>
                <div className="d-flex mb-4">
                  <div className="contact-icon">
                    <FaPhoneVolume />
                  </div>
                  <div className="contact-dec">
                    <h3>Office Contact Number</h3>
                    <p className="mb-0">+91 7601932024</p>
                  </div>
                </div>
                <div className="d-flex mb-4">
                  <div className="contact-icon">
                    <BsFillTelephoneInboundFill />
                  </div>
                  <div className="contact-dec">
                    <h3>Landline Number</h3>
                    <p className="mb-0">03484-257730</p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="contact-icon">
                    <BsFillEnvelopeCheckFill />
                  </div>
                  <div className="contact-dec">
                    <h3>Email Address</h3>
                    <p className="mb-0">beop.principal@gmail.com</p>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="contact-form">
                <h2>Please fill up the form</h2>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-2">
                      <input
                        value={handelContact.name}
                        onChange={(e) => {
                          handleChange(e);
                          setHandelContactError((item) => {
                            return {
                              ...item,
                              name: false,
                            };
                          });
                        }}
                        type="text"
                        name="name"
                        placeholder="Name"
                        className="form-control"
                      />
                      <VisibleError
                        show={handelContactError.name}
                        name="Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-2">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="form-control"
                        value={handelContact.email}
                        onChange={(e) => {
                          handleChange(e);
                          setHandelContactError((item) => {
                            return {
                              ...item,
                              email: false,
                              emailValidate: false,
                            };
                          });
                        }}
                      />
                      <VisibleError
                        show={handelContactError.email}
                        name="Email"
                      />

                      {handelContactError.emailValidate ? (
                        <span
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingLeft: "10px",
                          }}
                        >
                          Please Enter a Valid Email!
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-2">
                      <input
                        type="tel"
                        name="mobileNo"
                        placeholder="Number"
                        className="form-control"
                        value={handelContact.mobileNo}
                        onChange={(e) => {
                          handleChange(e, 10);
                          setHandelContactError((item) => {
                            return {
                              ...item,
                              mobileNo: false,
                            };
                          });
                        }}
                      />
                      <VisibleError
                        show={handelContactError.mobileNo}
                        name="Number"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-2">
                      <textarea
                        type="text"
                        name="massage"
                        rows={5}
                        placeholder="Write your massage"
                        className="form-control"
                        value={handelContact.massage}
                        onChange={(e) => {
                          handleChange(e);
                          setHandelContactError((item) => {
                            return {
                              ...item,
                              massage: false,
                            };
                          });
                        }}
                      ></textarea>
                      <VisibleError
                        show={handelContactError.massage}
                        name="Write your massage"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    {/* <Link to="/">Submit Now</Link> */}
                    <button className="btn" onClick={handelSubmit}>
                      Submit Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="con-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3646.1452633343665!2d88.0496591!3d23.955302900000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fbdda555555551%3A0x27605d718a52ecf9!2sGAAT%20FOUNDATION%20TRUST!5e0!3m2!1sen!2sin!4v1719409000184!5m2!1sen!2sin"
          allowFullScreen=""
          title="GAAT"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default Contact;
