import React from "react";
import "./Slider.css";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { bannerOne, bannerThree, bannerTwo } from "../../images";
// import { SliderFour, SliderOne, SliderThree, SliderTwo } from "../../images";

const Slider = () => {
  return (
    <>
      <Carousel className="banner">
        <Carousel.Item interval={1000}>
          <img className="w-100" src={bannerOne} alt="" />
          <Carousel.Caption>
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7">
                  <div className="slider-content">
                    <h5 className="animate fadeInUp one">Gaat Pvt. ITI</h5>
                    <h4 className="animate fadeInUp one">
                      More than 20 years experience
                    </h4>
                    <h2 className="animate fadeInUp one">
                      Education is the best key success in life
                    </h2>
                    <p className="animate fadeInUp two">
                      Affiliation No: DGET/6/25/2012-TC Dated on 27.07.2012
                      <br /> All our courses are Approved by Central Government
                      & State Government Universities.
                    </p>
                    <div className="slider-btn animate fadeInUp three">
                      <Link
                        className="btn"
                        to="/about-college"
                        target="_blank"
                        style={{ marginRight: "7px" }}
                      >
                        Read More
                        <BsArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5"></div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="w-100" src={bannerTwo} alt="" />
          <Carousel.Caption>
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7">
                  <div className="slider-content">
                    <h5 className="animate fadeInUp one">Gaat Pvt. ITI</h5>
                    <h4 className="animate fadeInUp one">
                      More than 20 years experience
                    </h4>
                    <h2 className="animate fadeInUp one">
                      Education is the best key success in life
                    </h2>
                    <p className="animate fadeInUp two">
                      Affiliation No: DGET/6/25/2012-TC Dated on 27.07.2012
                      <br /> All our courses are Approved by Central Government
                      & State Government Universities.
                    </p>
                    <div className="slider-btn animate fadeInUp three">
                      <Link
                        className="btn"
                        to="/about-college"
                        target="_blank"
                        style={{ marginRight: "7px" }}
                      >
                        Read More
                        <BsArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5"></div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="w-100" src={bannerThree} alt="" />
          <Carousel.Caption>
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7">
                  <div className="slider-content">
                    <h5 className="animate fadeInUp one">
                      WelCome to Gaat Pvt. ITI
                    </h5>
                    <h4 className="animate fadeInUp one">
                      More than 20 years experience
                    </h4>
                    <h2 className="animate fadeInUp one">
                      Education is the best key success in life
                    </h2>
                    <p className="animate fadeInUp two">
                      Affiliation No: DGET/6/25/2012-TC Dated on 27.07.2012
                      <br /> All our courses are Approved by Central Government
                      & State Government Universities.
                    </p>
                    <div className="slider-btn animate fadeInUp three">
                      <Link
                        className="btn"
                        to="/about-college"
                        target="_blank"
                        style={{ marginRight: "7px" }}
                      >
                        Read More
                        <BsArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5"></div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default Slider;
