import React from "react";
import { FaBars, FaEnvelope, FaFacebook } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { MdCall } from "react-icons/md";
import "./Header.css";
import { Logo } from "../../images";

const Header = () => {
  return (
    <>
      <div className="site-mobile-menu site-navbar-target">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close mt-3">
            <span className="icon-close2 js-menu-toggle">
              <RxCross1 />
            </span>
          </div>
        </div>
        <div className="site-mobile-menu-body"></div>
      </div>
      <div className="site-navbar-wrap">
        <div className="header-top">
          <div className="side-gap">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 text-right">
                  <div className="header-cta">
                    <ul className="d-flex align-items-center">
                      <li>
                        <div className="call-box">
                          <div className="text">
                            For Admission Call
                            <MdCall />
                            <Link to="tel:+91 9339955219">+91 9339955219</Link>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="call-box">
                          <div className="text">
                            <FaEnvelope />
                            <Link to="to:gaatpvtiti@gmail.com">
                              gaatpvtiti@gmail.com
                            </Link>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 ">
                  <div className="header-social text-right">
                    <span>
                      Follow us:-
                      <Link
                        to="https://www.facebook.com/kandigaat.iti"
                        target="_blank"
                      >
                        <FaFacebook />
                      </Link>
                      {/* <Link
                        to="https://www.instagram.com/bengal_pharmacy_official/?igsh=bjQxY2NuNG9xcnJu"
                        target="_blank"
                      >
                        <FaInstagram />
                      </Link>
                      <Link
                        to="https://web.whatsapp.com/send/?phone=7601932023&text&type=phone_number&app_absent=0"
                        target="_blank"
                      >
                        <FaWhatsapp />
                      </Link> */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-navbar site-navbar-target js-sticky-header">
          <div className="side-gap">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-2 col-md-4 col-sm-9 col-8 main-logo">
                  <div className="d-flex align-items-center">
                    <div>
                      <Link to="/">
                        <img src={Logo} alt="logo" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 col-md-8 col-sm-3 col-4">
                  <nav className="site-navigation text-right" role="navigation">
                    <div className="container">
                      <div className="d-inline-block d-lg-none ml-md-0 mr-auto py-3">
                        <Link
                          to="/"
                          className="site-menu-toggle js-menu-toggle text-white"
                        >
                          <span className="icon-menu h3">
                            <FaBars />
                          </span>
                        </Link>
                      </div>
                      <ul className="site-menu main-menu js-clone-nav d-none d-lg-block">
                        <li>
                          <Link to="/" className="nav-link">
                            Home
                          </Link>
                        </li>
                        <li className="has-children">
                          <Link href="#" className="nav-link">
                            About Us
                          </Link>
                          <ul className="dropdown arrow-top">
                            <li>
                              <Link to="/about-college" className="nav-link">
                                About College
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/recognition-affiliation"
                                className="nav-link"
                              >
                                Recognition & Affiliation
                              </Link>
                            </li>
                            <li>
                              <Link to="/chairman-message" className="nav-link">
                                Chairman's Message
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/principal-message"
                                className="nav-link"
                              >
                                Principal's Message
                              </Link>
                            </li>
                            <li>
                              <Link to="/faculties" className="nav-link">
                                Faculty List
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/gallery" className="nav-link">
                            Gallery
                          </Link>
                        </li>
                        <li className="has-children">
                          <Link href="#" className="nav-link">
                            Admission
                          </Link>
                          <ul className="dropdown arrow-top">
                            <li>
                              <Link to="/course-details" className="nav-link">
                                Course Details
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/admission-criteria"
                                className="nav-link"
                              >
                                Admission Criteria
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/admission-process"
                                className="nav-link"
                              >
                                Admission Process
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="has-children">
                          <Link href="#" className="nav-link">
                            Special Cells and Committee
                          </Link>
                          <ul className="dropdown arrow-top">
                            <li>
                              <Link to="/placement-cell" className="nav-link">
                                Placement Cell
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/anti-ragging-cell"
                                className="nav-link"
                              >
                                Anti-Ragging Cell
                              </Link>
                            </li>
                            <li>
                              <Link to="/grievance-cell" className="nav-link">
                                Grievance Cell
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/contact-us" className="nav-link">
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
