import axios from "axios";

const fetchApi = () => {
  const defaultOption = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };

  let instance = axios.create(defaultOption);

  return instance;
};

export const apiClient = {
  post(path, params) {
    return fetchApi().post(path, params);
  },
  put(path, params) {
    return fetchApi().put(path, params);
  },
  get(path) {
    return fetchApi().get(path);
  },
  delete(path) {
    return fetchApi().delete(path);
  },
};

export default apiClient;
