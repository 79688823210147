import React from "react";
import { Link } from "react-router-dom";

const AdmissionProcess = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Admission Process</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Admission Process</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="prospectus-structure">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>
                Academic session at GAAT Pvt. ITI begins from the month of
                August in every year and hence the applications in prescribed
                form are taken from the eligible candidates from the month of
                March. On the basis of the applications received from the
                prospective candidates, the trainees are selected on the basis
                of their marks obtained at the 10th Standards as well as
                performance in the personal interview. The selected trainees
                have to pay admission fee and to deposit caution money at the
                time of admission.
              </p>
              <p>
                The following documents are to be submitted at the time of
                Admission :{" "}
              </p>
              <p>
                <b>1.</b>Admit Card of Madhyamik(10th) -(Xerox) <br />
                <b>2.</b>Marksheet of Madhyamik(10th) -(Xerox) <br />
                <b>3.</b>Pass Certificate of Madhyamik(10th) -(Xerox) <br />
                <b>4.</b>Aadhar Card of Candidate -(Xerox) <br />
                <b>5.</b>Father’s and Mother’s Aadhar card of Candidate -(Xerox)
                <br />
                <b>6.</b>Caste Certificate (if any) - (Xerox)
                <br />
                <b>7.</b>Passport Size Color Photo (6 pcs) <br />
                <b>8.</b>Bank Account Passbook of Candidate -(Xerox)
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdmissionProcess;
